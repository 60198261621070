import React from 'react'
import PageWrapper from '../components/pagewrapper'
import Header from '../components/header'
import Breadcrumb from '../components/breadcrumb'
import {graphql} from 'gatsby'
import Footer from '../components/footer'
import {Helmet} from 'react-helmet'
import bg1 from '../../static/bg-1.jpg';
import {Link} from 'gatsby'




class Mainservices extends React.Component{
  constructor(props) {
    super(props)
  }

  render() {
    var menu;
    if(this.props.pageContext.langcode === 'EN') {// Gia na perasei to analogo menu ston header
       menu = this.props.pageContext.menuen.links
    }
    else {
      menu = this.props.pageContext.menuel.links
    }
    var langpath =[]
    this.props.pageContext.langcontainer.forEach((item) => { // Gia na presasei ta url sto header
      langpath.push({path:item.entityUrl.path, id:item.entityLanguage.id.toUpperCase()})
    })

    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
  var breadpath = this.props.location.pathname.split(/[/]/)
  breadpath.shift()

  breadpath.forEach((item,i) => {
    var value = null;
    var path = null;
    // console.log(item,i,loc[0])

    if(i === 0) {
      if(item === 'en' || item === 'el') {
        value = 'HOME';
        path=`/${item}`
      }
      else {
        value=item;
        path=`/${item}`
      }
      loc.push({value: value, path: path})
    }
    else {
      value=item;
      path=`${loc[i-1].path}/${item}`
      loc.push({value: value, path: path})
    }





  })


       return (
         <div>
           <Helmet>
             {this.props.pageContext.metainfo.map((item,i) => {
       if(item.key === 'title') {
         return (
           <title key={i}>{item.value}</title>
         )
       }
       else if(item.key === 'canonical') {
         return (
           <link key={i} rel="canonical" href={this.props.path} />
         )
       }

       else if(item.key === 'description') {
         return (
           <meta key={i} name="description" content={item.value}/>
         )
       }

       else if(item.key === 'og:image') {
         return (
           <meta property="og:image" content={item.imageFile.publicURL}/>
         )
       }



       else {
         return null;
       }
     })}
           </Helmet>

          <PageWrapper>
        <Header info={this.props.data.drupal.contactInfo} logourl={this.props.data.drupal.headerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} language={this.props.pageContext.langcode} menu={menu} langcontainer={langpath} path={this.props.path}/>
        <Breadcrumb background={this.props.pageContext.background} title={this.props.pageContext.title} path={loc}/>
        <div className="section-full p-t80 p-b50 bg-white inner-page-padding">

    <div className="container">



                <div className="section-content">
                  <div className="row">
                        <div className="col-md-6 col-sm-6">


                            <div className="section-head">
                                <div className="mt-separator-outer separator-left">
                                    <div className="mt-separator">
                                      {this.props.pageContext.langcode === 'EN'? <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> services</h2>
                                    : <h2 className="text-uppercase sep-line-one ">Οι  <span className="font-weight-300 text-primary">υπηρεσιες</span> μας</h2> }

                                    </div>
                                </div>
                            </div>

                            <div className="section-content p-b30">
                                <div className="mt-accordion acc-bg-gray" id="accordion5">
                                  {this.props.data.drupal.nodeQuery.entities.map((item,i)=> {
                                    return (
                                      <div key={i} className="panel mt-panel">
                                          <div className="acod-head acc-actives">
                                               <h6 className="acod-title acc-actives">
                                                  <a data-toggle="collapse" href={`#collapseOne${i}`} data-parent="#accordion5" aria-expanded="false" className="collapsed">
                                                      {item.title}
                                                      <span className="indicator"><i className="fa"></i></span>
                                                  </a>
                                               </h6>
                                          </div>
                                          <div id={`collapseOne${i}`} className="acod-body collapse" aria-expanded="false" style={{height:'0px'}}>
                                              <div  className="acod-content p-tb15" dangerouslySetInnerHTML={{ __html: item.body.processed }}></div>
                                          </div>
                                      </div>
                                    )
                                  })}



                                </div>
                           </div>
                        </div>
                    <div className="col-md-6 col-sm-6">


                            <div className="section-head">
                                <div className="mt-separator-outer separator-left">
                                    <div className="mt-separator">
                                      {this.props.pageContext.langcode === 'EN'? <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Our</span> Approach</h2>
                                      : <h2 className="text-uppercase sep-line-one ">Οι <span className="font-weight-300 text-primary">αξιες</span> μας</h2>}

                                    </div>
                                </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                              <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-primary center">
                                        <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                            <span className="icon-cell text-primary"><i className="flaticon-crane"></i></span>
                                        </div>
                                        <div className="icon-content text-black">
                                            <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">{this.props.pageContext.langcode === 'EN'? 'security' : 'ασφαλεια'}</h4>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                              <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-dark center">
                                        <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                                            <span className="icon-cell text-primary"><i className="flaticon-chart"></i></span>
                                        </div>
                                        <div className="icon-content text-white">
                                            <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">{this.props.pageContext.langcode === 'EN'? 'reliability' : 'αξιοπιστια'}</h4>


                                        </div>
                                    </div>
                                </div>

                            </div>
                    </div>
                </div>
            </div>

         </div>
       </div>

       {/*Section Contact  */}
      <div className="section-full p-tb80 overlay-wraper bg-center bg-parallax bg-cover" data-stellar-background-ratio="0.5" style={{backgroundImage: `url(${bg1})`, backgroundPosition: '50% -12.5834px'}}>
       <div className="overlay-main bg-primary opacity-07"></div>
          <div className="container">

     <div className="section-content">
       <div className="call-us-section text-center">
                          <h4 className="m-b15">{this.props.pageContext.langcode === 'EN'? 'For more informations' : 'Για περισσότερες πληροφορίες'}</h4>
                          <h2 className="call-us-number m-b15 m-b0">{this.props.data.drupal.contactInfo.fieldPhone}</h2>
                          <h4 className="call-us-address m-t0 m-b20">{this.props.data.drupal.contactInfo.fieldAddress}</h4>
                          <Link className="site-button-secondry btn-effect bg-dark" to={this.props.pageContext.langcode === 'EN'? '/en/contact': '/el/contact'}>
                           {this.props.pageContext.langcode === 'EN'? 'Contact us': 'Επικοινωνια'}
                          </Link>

       </div>
     </div>

          </div>
      </div>

       <Footer social={this.props.data.drupal.social} logourl={this.props.data.drupal.footerLogo.fieldLogoimage.entity.thumbnail.imageFile.publicURL} language={this.props.pageContext.langcode} menu={menu}  info={this.props.data.drupal.contactInfo}/>



         </PageWrapper>
        </div>
       )
  }






}

export default Mainservices;


export const query = graphql`
query($langcode: DRUPAL_LanguageId!)  {
  drupal {
    footerLogo:nodeById(id: "25") {
     ... on DRUPAL_NodeLogo {

       fieldLogoimage {
          entity {
            thumbnail {
              url
              alt
              imageFile {
                publicURL
              }
            }
          }
        }
     }
   }
    headerLogo: nodeById(id: "24") {
   entityLabel
   ... on DRUPAL_NodeLogo {

     fieldLogoimage {
          entity {
            thumbnail {
              url
              alt
              imageFile {
                publicURL
              }
            }
          }
        }
   }
 }
    contactInfo: nodeById(id: "26", language: $langcode) {
   title
   ... on DRUPAL_NodeContactInfo {
     fieldAddress
     fieldEmail
     fieldPhone
   }
 }
 social:nodeQuery(filter: {conditions: [{field: "type", value: "Social_Links"}]}) {
    entities {
      ... on DRUPAL_NodeSocialLinks {

        fieldIcon
        fieldLink
      }
    }
  }
nodeQuery(filter: {conditions: [{field: "type", value: "services", language: $langcode}]}) {
 entities(language: $langcode) {
   ... on DRUPAL_NodeServices {
     title
     body {
       processed
     }
     entityUrl {
      path
    }

   }
 }
}
}
}
`
